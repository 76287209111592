import DOMPurify from 'isomorphic-dompurify';
import { marked, Renderer, Tokens } from 'marked';

export const markdown = (src: string) => {
  const renderer = new Renderer();
  renderer.link = renderLink;
  renderer.image = renderImage;

  const dirty = marked(src, {
    gfm: true,
    breaks: true,
    pedantic: false,
    renderer,
  }) as string;

  return DOMPurify.sanitize(dirty, { ADD_ATTR: ['target'] });
};

/**
 * Link renderer. Extends `marked` implementation with new option `linksInNewTab`.
 */
const renderLink = function ({ href, title, tokens }: Tokens.Link) {
  const text = this.parser.parseInline(tokens);
  const cleanHref = cleanUrl(href);
  if (cleanHref === null) {
    return text;
  }
  href = cleanHref;
  return `<a href="${href}" target="_blank" ${title ? `title=${title}` : ''}>${text}</a>`;
};

/**
 * Image renderer.
 *
 * Extends `marked` implementation with size info in title field.
 *
 * e.g. ![Alt Text](src.png "{200x100} My Title") describes an image with:
 * src = src.png
 * alt-text = 'Alt Text'
 * title = 'My Title'
 * width = 200px
 * height = 100px
 *
 * Valid size strings also include "{x100}", "{200X100}", "{200*100}",
 * & "{100px*200px}".
 *
 */
const renderImage = function ({ href, title, text }: Tokens.Image) {
  if (this.options.baseUrl && !originIndependentUrl.test(href)) {
    href = resolveUrl(this.options.baseUrl, href);
  }

  const { width, title: cleanTitle } = parseImageTitle(title);

  const widthAsNumber = width !== undefined ? Number(width) : undefined;
  const imageWidth =
    !isNaN(widthAsNumber) && widthAsNumber > 0 ? `${widthAsNumber}px` : '100%';

  let out = `<img src="${href}" alt="${text}" width="100%" style="max-width:${imageWidth}"`;
  if (cleanTitle) {
    out += ' title="' + cleanTitle + '"';
  }
  out += this.options.xhtml ? '/>' : '>';
  return out;
};

const parseImageTitle = (title: string) => {
  const dimensions = title?.match(/^{(\d*)(?:px)*[xX*]*(\d*)(?:px)*}\s*(.*)/);

  const safeGroup = (i: number, defaultVal?: string) =>
    (dimensions && dimensions[i]) ?? defaultVal;

  return {
    width: safeGroup(1),
    height: safeGroup(2),
    title: safeGroup(3, title),
  };
};

/**
 * Directly copied from marked since they're not available externally.
 * We're not customizing these methods and only copied them to keep our custom
 * renderers as close to the original as possible.
 */

function resolveUrl(base: any, href: any) {
  if (!baseUrls[' ' + base]) {
    // we can ignore everything in base after the last slash of its path component,
    // but we might need to add _that_
    // https://tools.ietf.org/html/rfc3986#section-3
    if (/^[^:]+:\/*[^/]*$/.test(base)) {
      baseUrls[' ' + base] = base + '/';
    } else {
      baseUrls[' ' + base] = rtrim(base, '/', true);
    }
  }
  base = baseUrls[' ' + base];

  if (href.slice(0, 2) === '//') {
    return base.replace(/:[\s\S]*/, ':') + href;
  } else if (href.charAt(0) === '/') {
    return base.replace(/(:\/*[^/]*)[\s\S]*/, '$1') + href;
  } else {
    return base + href;
  }
}
const baseUrls: any = {};
const originIndependentUrl = /^$|^[a-z][a-z0-9+.-]*:|^[?#]/i;

// Remove trailing 'c's. Equivalent to str.replace(/c*$/, '').
// /c*$/ is vulnerable to REDOS.
// invert: Remove suffix of non-c chars instead. Default falsey.
function rtrim(str: any, c: any, invert: any) {
  if (str.length === 0) {
    return '';
  }

  // Length of suffix matching the invert condition.
  let suffLen = 0;

  // Step left until we fail to match the invert condition.
  while (suffLen < str.length) {
    const currChar = str.charAt(str.length - suffLen - 1);
    if (currChar === c && !invert) {
      suffLen++;
    } else if (currChar !== c && invert) {
      suffLen++;
    } else {
      break;
    }
  }

  return str.substr(0, str.length - suffLen);
}

function cleanUrl(href: string) {
  try {
    href = encodeURI(href).replace(/%25/g, '%');
  } catch {
    return null;
  }
  return href;
}
