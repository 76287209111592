/** @jsxImportSource @emotion/react */
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useMemo } from 'react';
import { IListItem, IRenderListRow } from '../types.ts';

export interface IListInnerListItemProps {
  renderItem: IRenderListRow;
  id: string | number;
  index: number;
  data: IListItem;
  isSelected: boolean;
  isFocused: boolean;
  isFirst: boolean;
  isLast: boolean;
  onClick?: () => void;
  onDoubleClick?: () => void;
}

const View: React.FC<IListInnerListItemProps> = ({
  renderItem,
  id,
  index,
  data,
  isSelected,
  isFocused,
  isFirst,
  isLast,
  onDoubleClick,
  onClick,
}) => {
  const elContent = useMemo(
    () =>
      renderItem({
        id,
        index,
        data,
        isSelected,
        isFocused,
        isFirst,
        isLast,
      }),
    [data, id, index, isFirst, isFocused, isLast, isSelected, renderItem],
  );

  return (
    <div
      data-test={`innerList-selectionListItem-${id.toString()}`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {elContent}
    </div>
  );
};

export const ListInnerListItem = memo(View, propsAreEqualFastCompare);
