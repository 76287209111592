/** @jsxImportSource @emotion/react */
import { Paper as MaterialPaper, makeStyles } from '@material-ui/core';
import React from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import { CSSProperties } from '../../types/types.ts';

const usePaperStyles = ({ style, elevation = 1 }: IPaperProps) =>
  makeStyles({
    root: {
      zIndex: elevation,
      ...CommonStyles.MaterialCubicTransitions,
      ...style,
    },
  });

export interface IPaperProps {
  children?: React.ReactNode;

  // Material UI Props.
  elevation?: number;
  isSquare?: boolean;
  variant?: 'elevation' | 'outlined';

  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onFocus?: React.FocusEventHandler<object>;
  onBlur?: React.FocusEventHandler<object>;

  style?: CSSProperties;
}

/**
 * Wrapper for the Material UI <Paper /> wrapper that gives elevation to surfaces as necessary.
 *
 * @see https://material-ui.com/components/paper
 * @see https://material-ui.com/api/paper
 */
export const Paper = ({
  elevation = 1,
  isSquare = false,
  variant = 'elevation',
  children,
  onClick,
  onBlur,
  onFocus,
  style,
}: IPaperProps) => {
  const classes = usePaperStyles({ style, elevation })();

  return (
    <MaterialPaper
      elevation={elevation}
      square={isSquare}
      variant={variant}
      classes={{ root: classes.root }}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {children}
    </MaterialPaper>
  );
};
