import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ScrollState = {
  containers: {
    [containerId: string]: {
      scrollToDataId?: string;
      offsetPx?: number;
      smooth?: boolean;
    };
  };
  dataIdInViewReferenceCount: {
    [dataId: string]: number;
  };
};

const DEFAULT_STATE: ScrollState = {
  containers: {},
  dataIdInViewReferenceCount: {},
};

const slice = createSlice({
  name: 'global/scroll',
  initialState: DEFAULT_STATE,
  reducers: {
    clearScrollToDataId(
      state,
      action: PayloadAction<{ scrollContainerId: string }>,
    ) {
      const { scrollContainerId } = action.payload;
      if (!state.containers[scrollContainerId])
        state.containers[scrollContainerId] = {};

      state.containers[scrollContainerId].scrollToDataId = undefined;
      state.containers[scrollContainerId].offsetPx = undefined;
      state.containers[scrollContainerId].smooth = undefined;
    },
    clearTrackedDataIds(state) {
      state.dataIdInViewReferenceCount = {};
    },
    scrollToDataId(
      state,
      action: PayloadAction<{
        scrollContainerId: string;
        dataId: string;
        offsetPx?: number;
        smooth?: boolean;
      }>,
    ) {
      const {
        scrollContainerId,
        dataId,
        offsetPx = 0,
        smooth,
      } = action.payload;
      if (!state.containers[scrollContainerId])
        state.containers[scrollContainerId] = {};

      state.containers[scrollContainerId].scrollToDataId = dataId;
      state.containers[scrollContainerId].offsetPx = offsetPx;
      state.containers[scrollContainerId].smooth = smooth;
    },
    trackDataIdInView(
      state,
      action: PayloadAction<{
        dataId: string;
        parentDataId?: string;
        inViewport: boolean;
      }>,
    ) {
      const { dataId, parentDataId, inViewport } = action.payload;
      const refAddition = inViewport ? 1 : -1;

      const id = parentDataId ?? dataId;

      const refCountState = state.dataIdInViewReferenceCount;
      refCountState[id] = Math.max(0, (refCountState[id] ?? 0) + refAddition);
    },
  },
});

export const {
  clearScrollToDataId,
  clearTrackedDataIds,
  scrollToDataId,
  trackDataIdInView,
} = slice.actions;

export const reducer = slice.reducer;
export type State = ScrollState;
