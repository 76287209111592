/** @jsxImportSource @emotion/react */
import { events } from '@seeeverything/ui.util/src/events.rx/index.ts';
import React from 'react';
import { distinctUntilKeyChanged, filter, Subject, takeUntil } from 'rxjs';
import { FocusState, IFocusChangeEvent } from '../FocusState/FocusState.tsx';
import {
  ActionKeys,
  IKey,
  IListSelectionBehaviorProps,
  IListSelectionBehaviorState,
  IListSelectionNavigationOption,
  ISelectableListItem,
  ISelection,
  ListItemSelectedEventHandler,
  SelectionEdge,
  SelectionEdgeEvent,
  SelectReason,
} from './types.ts';

const getHomeIndex = (options: IListSelectionNavigationOption) => {
  const { items = [], cycle } = options;
  return closestSelectableIndex({
    items,
    cycle,
    index: 0,
    step: +1, // Step forward to first selectable index.
  });
};

const getEndIndex = (options: IListSelectionNavigationOption) => {
  const { items = [], cycle } = options;
  return closestSelectableIndex({
    items,
    cycle,
    index: items.length - 1,
    step: -1, // Step back to first selectable index.
  });
};
const getPreviousIndex = (options: IListSelectionNavigationOption) => {
  const { items = [], cycle, currentIndex } = options;
  return closestSelectableIndex({
    items,
    cycle,
    index: currentIndex - 1,
    step: -1,
  });
};
const getNextIndex = (options: IListSelectionNavigationOption) => {
  const { items = [], cycle, currentIndex } = options;
  return closestSelectableIndex({
    items,
    cycle,
    index: currentIndex + 1,
    step: +1,
  });
};

/**
 * Wrapper that handles the selection state of a child list.
 */
export class ListSelectionBehavior extends React.Component<
  IListSelectionBehaviorProps,
  IListSelectionBehaviorState
> {
  public static defaultProps: IListSelectionBehaviorProps = {
    tabIndex: 0,
    isStateful: true,
  };
  public state: IListSelectionBehaviorState = {
    isFocused: this.props.isFocused || false,
    selectedId: this.props.selectedId,
  };

  private unmounted$ = new Subject<void>();
  private props$ = new Subject<IListSelectionBehaviorProps>();
  private selectionChanged$ = new Subject<{
    selectedId?: string | number;
    reason: SelectReason;
  }>();

  private focusState: FocusState;
  private focusStateRef = (ref: FocusState) => (this.focusState = ref);

  public UNSAFE_componentWillMount() {
    const isKeysEnabled = this.props.keys$ !== null;
    const props$ = this.props$.pipe(takeUntil(this.unmounted$));
    const selectionChanged$ = this.selectionChanged$.pipe(
      takeUntil(this.unmounted$),
    );
    const keys$ = (this.props.keys$ ?? events.keydown$).pipe(
      takeUntil(this.unmounted$),
      filter(() =>
        Boolean(
          this.isFocused && isKeysEnabled && Boolean(this.props.selectedId),
        ),
      ),
    );

    props$
      // Keep `selectedId` state in sync with props.
      .pipe(distinctUntilKeyChanged('selectedId'))
      .subscribe((props) => {
        const { selectedId } = props;
        this.selectionChanged$.next({ selectedId, reason: 'PROP' });
      });

    selectionChanged$
      // Handle selection changes.
      .pipe(distinctUntilKeyChanged('selectedId'))
      .subscribe((e) => {
        const { selectedId } = e;
        const previousId = this.state.selectedId;

        const selectedIndex = this.indexOf(selectedId);

        if (selectedIndex !== -1) {
          const newSelectedIndex = closestSelectableIndex({
            index: selectedIndex,
            items: this.props.items,
            step: 1,
          });

          const newSelectedItem = this.item(newSelectedIndex);
          const newSelectedId = newSelectedItem && newSelectedItem.id;

          this.fireSelectionChanged(e.reason, previousId, newSelectedId);
          if (this.props.isStateful) {
            this.setState({ selectedId: newSelectedId });
          }
        }
      });

    // Handle key events.
    const KEYS = {
      previous: formatKeys(this.props.previousKey, { code: 'ArrowUp' }),
      next: formatKeys(this.props.nextKey, { code: 'ArrowDown' }),
      home: formatKeys(
        this.props.homeKey,
        { code: 'Home' },
        { code: 'ArrowUp', meta: true },
      ),
      end: formatKeys(
        this.props.endKey,
        { code: 'End' },
        { code: 'ArrowDown', meta: true },
      ),
      left: formatKeys(this.props.leftKey, { code: 'ArrowLeft' }),
      right: formatKeys(this.props.rightKey, { code: 'ArrowRight' }),
    };

    keys$
      .pipe(filter((e) => isKey(e, KEYS.home)))
      .subscribe(() => this.selectHome());

    keys$
      .pipe(filter((e) => isKey(e, KEYS.end)))
      .subscribe(() => this.selectEnd());

    keys$.pipe(filter((e) => isKey(e, KEYS.previous))).subscribe((e) => {
      e.preventDefault();
      if (this.isFirstSelected) {
        this.fireEdge('TOP');
      }
      this.selectPrevious();
    });

    keys$.pipe(filter((e) => isKey(e, KEYS.next))).subscribe((e) => {
      e.preventDefault();
      if (this.isLastSelected) {
        this.fireEdge('BOTTOM');
      }
      this.selectNext();
    });

    keys$.pipe(filter((e) => isKey(e, KEYS.left))).subscribe((e) => {
      e.preventDefault();
      this.fireEdge('LEFT');
    });

    keys$.pipe(filter((e) => isKey(e, KEYS.right))).subscribe((e) => {
      e.preventDefault();
      this.fireEdge('RIGHT');
    });

    // Initialize.
    this.props$.next(this.props);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: IListSelectionBehaviorProps,
  ) {
    this.props$.next(nextProps);
  }

  public componentWillUnmount() {
    this.unmounted$.next(null);
  }

  /**
   * Assigns focus to the list.
   */
  public focus() {
    this.focusState.focus();
  }

  /**
   * Retrieves the ID of the currently selected item.
   */
  public get selectedId(): string | number | undefined {
    return this.state.selectedId;
  }

  /**
   * Retrieves the currently selected item.
   */
  public get selectedItem(): any {
    return this.item(this.selectedIndex);
  }

  /**
   * Retrieves the index of the currently item.
   */
  public get selectedIndex(): number {
    return this.indexOf(this.state.selectedId);
  }

  public get selection(): ISelection | undefined {
    const index = this.selectedIndex;
    const item = this.item(index);
    return index < 0 ? undefined : { index, item };
  }

  /**
   * Determines whether the first selectable item is currently selected.
   */
  public get isFirstSelected() {
    return isFirstIndex(this.selectedIndex, this.props.items);
  }

  /**
   * Determines whether the last selectable item is currently selected.
   */
  public get isLastSelected() {
    return isLastIndex(this.selectedIndex, this.props.items);
  }

  /**
   * Retrieves the item for the given index.
   */
  public item(index: number): ISelectableListItem | undefined {
    const items = this.props.items || [];
    return items[index];
  }

  /**
   * Selects the item with the specified ID.
   */
  public selectId = (id: string | number, reason: SelectReason) => {
    this.selectIndex(this.indexOf(id), reason);
  };

  /**
   * Selects the item at the given index.
   */
  public selectIndex = (index: number, reason: SelectReason) => {
    const item = this.item(index);
    const selectedId = item ? item.id : undefined;
    if (isSelectable(item)) {
      this.selectionChanged$.next({ selectedId, reason });
    }
  };

  private fireSelectionChanged(
    reason: SelectReason,
    previousId?: string | number,
    nextId?: string | number,
  ) {
    const { onSelectionChanged } = this.props;
    const isChanged = previousId !== nextId;
    if (isChanged) {
      const previousIndex = this.indexOf(previousId);
      const nextIndex = this.indexOf(nextId);
      const args = {
        reason,
        from: {
          index: previousIndex,
          item: this.item(previousIndex),
        },
        to: {
          index: nextIndex,
          item: this.item(nextIndex),
        },
      };
      onSelectionChanged?.(args);
    }
  }

  private get isFocused() {
    const { tabIndex } = this.props;
    return (
      (tabIndex === -1 ? this.props.isFocused : this.state.isFocused) || false
    );
  }

  /**
   * Fires the [onEdgeSelected] event callback.
   */
  public fireEdge = (edge: SelectionEdge) => {
    const { onEdgeSelected } = this.props;
    if (onEdgeSelected) {
      const selection = this.selection;
      if (selection) {
        // There should always be a selection if this event gets fired.
        const args: SelectionEdgeEvent = {
          edge,
          selection,
          isCancelled: false,
          cancel() {
            this.isCancelled = true;
          },
        };
        onEdgeSelected(args);
      }
    }
  };

  public render() {
    const { items, children, tabIndex, tag } = this.props;
    let element = Array.isArray(children) ? children[0] : children;

    // Clone the list passing in expanded props.
    if (element) {
      const onSelect =
        (element as any).props && (element as any).props.onSelect;
      const props = {
        items,
        isFocused: this.isFocused,
        selectedId: this.state.selectedId,
        onSelect: this.itemSelectHandler(onSelect),
      };
      element = React.cloneElement(element as React.ReactElement<any>, props);
    }

    return (
      <FocusState
        ref={this.focusStateRef}
        tabIndex={tabIndex}
        onFocusChange={this.handleFocusChange}
        isFocused={this.props.isFocused}
        tag={tag}
        style={this.props.style}
      >
        {element}
      </FocusState>
    );
  }

  private handleFocusChange = (e: IFocusChangeEvent) => {
    this.setState({ isFocused: e.isFocused });
  };

  /**
   * Retrieves the index of the specified item.
   */
  private indexOf(id?: string | number): number {
    const items = this.props.items || [];
    return id === undefined ? -1 : items.findIndex((item) => item.id === id);
  }

  private itemSelectHandler =
    (originalHandler: ListItemSelectedEventHandler) =>
    (id: string | number) => {
      this.selectId(id, 'SELECTED');
      if (originalHandler) {
        originalHandler(id);
      }
    };

  private navigate = (
    reason: SelectReason,
    func: (options: IListSelectionNavigationOption) => number,
  ) => {
    const { items = [], cycle } = this.props;
    const index = func({ items, cycle, currentIndex: this.selectedIndex });
    this.selectIndex(index, reason);
    return index;
  };

  public static home = getHomeIndex;
  public static end = getEndIndex;
  public static previous = getPreviousIndex;
  public static next = getNextIndex;

  private selectHome = () => this.navigate('KEY:HOME', getHomeIndex);
  private selectEnd = () => this.navigate('KEY:END', getEndIndex);
  private selectPrevious = () =>
    this.navigate('KEY:PREVIOUS', getPreviousIndex);
  private selectNext = () => this.navigate('KEY:NEXT', getNextIndex);
}

/**
 * INTERNAL
 */
function isFirstIndex(index: number, items: ISelectableListItem[] = []) {
  if (index === 0) {
    return true;
  }
  const prev = closestSelectableIndex({
    index: index - 1,
    items,
    step: -1,
    cycle: false,
  });
  return prev < 0;
}

function isLastIndex(index: number, items: ISelectableListItem[] = []) {
  const MAX = items.length - 1;
  if (index === MAX) {
    return true;
  }
  const next = closestSelectableIndex({
    index: index + 1,
    items,
    step: +1,
    cycle: false,
  });
  return next > MAX;
}

function formatKeys(
  value: ActionKeys | undefined,
  ...defaultKeys: IKey[]
): IKey[] {
  if (value === null) {
    return [];
  }
  if (value === undefined) {
    return defaultKeys || [];
  }
  return Array.isArray(value) ? value : [value];
}

function isKeyMatch(e: React.KeyboardEvent, key: IKey): boolean {
  if (key.code !== e.code) {
    return false;
  }
  if (key.shift && !e.shiftKey) {
    return false;
  }
  if (key.ctrl && !e.ctrlKey) {
    return false;
  }
  if (key.meta && !e.metaKey) {
    return false;
  }
  return true;
}

function isKey(e: React.KeyboardEvent, compare: IKey[]): boolean {
  for (const key of compare) {
    if (isKeyMatch(e, key)) {
      return true;
    }
  }
  return false;
}

function isSelectable(item?: ISelectableListItem) {
  if (!item) {
    return false;
  }
  return item.isEnabled !== false && item.isSelectable !== false;
}

function closestSelectableIndex(options: {
  index: number;
  items?: ISelectableListItem[];
  cycle?: boolean;
  step: number;
}): number {
  const { items = [], cycle, step } = options;

  // Ensure index is within range.
  const MIN = 0;
  const MAX = items.length - 1;
  let index = formatIndexWithinRange({ index: options.index, items, cycle });

  // Determine if the item can be selected.
  const item = items[index];
  if (isSelectable(item)) {
    return index;
  }

  // Ensure the stepped index is not out of bounds.
  index = index + step;
  if (index > MAX || index < MIN) {
    if (cycle) {
      index = formatIndexWithinRange({ index, items, cycle });
    } else {
      return index;
    }
  }

  // RECURSION.
  return closestSelectableIndex({ index, items, cycle, step });
}

function formatIndexWithinRange(options: {
  index: number;
  items: ISelectableListItem[];
  cycle?: boolean;
}) {
  let { index } = options;
  const { items, cycle } = options;
  const MIN = 0;
  const MAX = items.length - 1;
  if (index < MIN) {
    index = cycle ? MAX : MIN;
  }
  if (index > MAX) {
    index = cycle ? 0 : MAX;
  }
  return index;
}
