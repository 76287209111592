/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useCallback, useMemo } from 'react';
import { ListItemSelectedEventHandler } from '../../ListSelectionBehavior/types.ts';
import { ScrollContainer } from '../../Scroll/ScrollContainer.tsx';
import {
  IListItem,
  IRenderListRow,
  IRenderListRowArgs,
  ListClickEventHandler,
} from '../types.ts';
import { ListInnerListItem } from './ListInnerListItem.tsx';

export interface IListInnerListProps {
  items: IListItem[];
  renderItem: IRenderListRow;
  isScrollable: boolean;
  onClick: ListClickEventHandler;
  onDoubleClick: ListClickEventHandler;
  style?: SerializedStyles;

  // From: <Selection list>.
  isFocused?: boolean;
  selectedId?: number | string;
  onSelect?: ListItemSelectedEventHandler;
}

/**
 * A simple list of rendered items without scrolling.
 * - Intended for lists of fewer than 10 elements.
 * - Height is determined by the DOM.
 */
const View: React.FC<IListInnerListProps> = ({
  items,
  isFocused = false,
  selectedId,
  isScrollable,
  style,
  renderItem,
  onSelect,
  onClick,
  onDoubleClick,
}) => {
  const itemSelectedHandler = useCallback(
    (reason: 'CLICK' | 'DOUBLE_CLICK', props: IRenderListRowArgs) => () => {
      const { id, index, data: item } = props;

      onSelect?.(id);
      if (reason === 'CLICK') onClick?.({ id, index, item });
      if (reason === 'DOUBLE_CLICK') onDoubleClick?.({ id, index, item });
    },
    [onClick, onDoubleClick, onSelect],
  );

  const elItems = useMemo(
    () =>
      items.map((data, index) => {
        const { id } = data;
        const isSelected = data.id === selectedId;
        const isFirst = index === 0;
        const isLast = index + 1 === items.length;
        const props = {
          id,
          index,
          data,
          isSelected,
          isFocused,
          isFirst,
          isLast,
        };
        return (
          <ListInnerListItem
            key={`row-${id}`}
            id={id}
            index={index}
            data={data}
            isSelected={isSelected}
            isFocused={isFocused}
            isFirst={isFirst}
            isLast={isLast}
            renderItem={renderItem}
            onClick={itemSelectedHandler('CLICK', props)}
            onDoubleClick={itemSelectedHandler('DOUBLE_CLICK', props)}
          />
        );
      }),
    [isFocused, itemSelectedHandler, items, renderItem, selectedId],
  );

  const elList = <div css={style}>{elItems}</div>;

  return isScrollable ? <ScrollContainer>{elList}</ScrollContainer> : elList;
};

export const ListInnerList = memo(View, propsAreEqualFastCompare);
