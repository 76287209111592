/** @jsxImportSource @emotion/react */
import { Interpolation, Theme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line import/extensions
import Popover from '@mui/material/Popover';

export interface IPopupProps {
  children?: React.ReactElement;
  hideOnClick?: boolean;
  onHide?: () => void;
  style?: Interpolation<Theme>;
}

/**
 * A popup overlay.
 */
export const Popup: React.FC<IPopupProps> = ({
  children,
  hideOnClick = true,
  onHide,
  style,
}) => {
  const [isInit, setIsInit] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const innerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!isInit) setIsInit(true);
  }, [isInit]);

  const anchorEl = containerRef.current?.parentElement;

  return (
    <div
      ref={containerRef}
      onClick={(e) => {
        const clickedInside = innerRef.current?.contains(e.target as Node);
        if (clickedInside && !hideOnClick) return;

        onHide?.();
        e.stopPropagation();
      }}
      css={style}
    >
      {anchorEl && (
        <Popover
          id={'popover-menu'}
          aria-labelledby={'popover-button'}
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          elevation={3}
        >
          <div ref={innerRef}>{children}</div>
        </Popover>
      )}
    </div>
  );
};
