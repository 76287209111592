/** @jsxImportSource @emotion/react */
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * Determines whether the given React element contains a focused
 * element anywhere within it's DOM hierarchy.
 */
export function containsFocus(el: React.ReactInstance) {
  // eslint-disable-next-line react/no-find-dom-node
  const reactNode = ReactDOM.findDOMNode(el);
  return reactNode ? reactNode.contains(document.activeElement) : false;
}
