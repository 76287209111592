/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import { Text } from '../../Text/Text.tsx';
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IEmptyMessageProps {
  children: ReactNode;
}

/**
 * Message that is displayed when a list is empty.
 */
export const EmptyMessage = ({ children }: IEmptyMessageProps) => {
  const message = children === true ? 'Nothing to display.' : children;

  return (
    <div css={styles.base}>
      <Text style={styles.text}>{message}</Text>
    </div>
  );
};

const styles = {
  base: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 42,
  }),
  text: css({
    fontStyle: 'italic',
    fontSize: 14,
    color: color.format(-0.3),
  }),
};
