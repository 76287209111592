/** @jsxImportSource @emotion/react */
import React from 'react';
import * as R from 'ramda';
import {
  Subject,
  takeUntil,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  delay,
} from 'rxjs';
import {
  SelectionList,
  ISelectionListOptions,
  SelectionListChangedEvent,
  ISelectionListColumn,
  SelectionListEdgeEvent,
  ISelectionListSelection,
} from '../SelectionList/index.ts';
import * as util from './util.ts';
import { ListClickEvent } from '../List/types.ts';
import { SelectionEdge, SelectReason } from '../ListSelectionBehavior/types.ts';

export interface ISelectionListHierarchyProps extends ISelectionListOptions {
  column?: ISelectionListColumn;
  selectedId?: string | number;
}

export interface ISelectionListHierarchyState {
  columns: ISelectionListColumn[];
}

/**
 * A SelectionList that supports "miller column" style selection.
 */
export class SelectionListHierarchy extends React.Component<
  ISelectionListHierarchyProps,
  ISelectionListHierarchyState
> {
  public state: ISelectionListHierarchyState = { columns: [] };
  private unmounted$ = new Subject<void>();
  private props$ = new Subject<ISelectionListHierarchyProps>();
  private edge$ = new Subject<SelectionListEdgeEvent>();
  private click$ = new Subject<ListClickEvent>();
  private selectionChanged$ = new Subject<SelectionListChangedEvent>();
  private fireSelectionChanged$ = new Subject<SelectionListChangedEvent>();

  private list: SelectionList;
  private listRef = (ref: SelectionList) => (this.list = ref);

  public get columns() {
    return this.list.columns; // API pass-through.
  }

  public focus(columnIndex = 0) {
    this.list.focus(columnIndex); // API pass-through.
  }

  public hasFocus() {
    return this.list.hasFocus(); // API pass-through.
  }

  public hasSelection(columnIndex = 0) {
    return this.list.hasSelection(columnIndex); // API pass-through.
  }

  public selection(columnIndex = 0): ISelectionListSelection | undefined {
    return this.list.selection(columnIndex); // API pass-through.
  }

  public fireEdge = (columnIndex = 0, edge: SelectionEdge) =>
    this.list.fireEdge(columnIndex, edge); // API pass-through.

  public UNSAFE_componentWillMount() {
    const props$ = this.props$.pipe(takeUntil(this.unmounted$));
    const click$ = this.click$.pipe(takeUntil(this.unmounted$));
    const edge$ = this.edge$.pipe(takeUntil(this.unmounted$));
    const selectionChanged$ = this.selectionChanged$.pipe(
      takeUntil(this.unmounted$),
    );
    const fireSelectionChanged$ = this.fireSelectionChanged$.pipe(
      takeUntil(this.unmounted$),
    );

    props$
      .pipe(
        // Build the "selection hierarchy" when the selection property changes.
        distinctUntilKeyChanged('selectedId'),
      )
      .subscribe((props) => {
        this.selectItem(props.selectedId, props.column, 'PROP');
      });

    props$
      .pipe(
        // Re-build the "selection hierarchy" when the root column changes.
        distinctUntilKeyChanged('column'),
      )
      .subscribe((props) => {
        this.selectItem(props.selectedId, props.column, 'PROP');
      });

    selectionChanged$
      .pipe(
        // Ensure selection is up-to-date
        filter((e) => e.reason.startsWith('KEY:')),
      )
      .subscribe((e) => {
        this.fireSelectionChanged$.next(e);
      });

    fireSelectionChanged$
      .pipe(
        // Fire [onSelectionChanged] event.
        filter(() => Boolean(this.props.onSelectionChanged)),
        filter((e) => !(e.from.column === -1 && e.to.column === -1)),
        filter(
          (e) => e.from.column !== e.to.column || e.from.index !== e.to.index,
        ),
        distinctUntilChanged(
          (prev, next) =>
            prev.from.column === next.from.column &&
            prev.to.column === next.to.column &&
            prev.from.index === next.from.index &&
            prev.to.index === next.to.index,
        ),
      )
      .subscribe((e) => {
        const { onSelectionChanged } = this.props;
        if (onSelectionChanged) {
          onSelectionChanged(e);
        }
      });

    click$
      // Build the "selection hierarchy" when the the user clicks an item.
      .subscribe((e) => {
        this.selectItem(e.id, this.props.column, 'SELECTED');
      });

    edge$
      .pipe(
        // Handle moving focus to sibling lists on RIGHT key.
        filter((e) => e.edge === 'RIGHT'),
        filter((e) => !e.isCancelled),
        delay(0), // NB: Prevent child list reacting to key event as well.
      )
      .subscribe((e) => {
        const columnIndex = R.clamp(0, this.columns.length, e.column + 1);
        this.selectFirst(columnIndex, 'EDGE');
        this.focusList(columnIndex);
      });

    edge$
      .pipe(
        // Handle moving focus to sibling lists on LEFT key.
        filter((e) => e.edge === 'LEFT'),
        filter((e) => !e.isCancelled),
      )
      .subscribe((e) => {
        const columns = this.state.columns;
        const columnIndex = R.clamp(0, columns.length, e.column - 1);
        const parentColumn = columns[columnIndex];
        const selectedIndex = parentColumn.selectedIndex;
        if (selectedIndex !== undefined) {
          const item = parentColumn.items[selectedIndex];
          this.selectItem(item.id, this.props.column, 'EDGE');
        } else {
          this.selectFirst(columnIndex, 'EDGE');
        }
        this.focusList(columnIndex);
      });
  }

  public componentDidMount() {
    this.props$.next(this.props);
  }
  public UNSAFE_componentWillReceiveProps(
    nextProps: ISelectionListHierarchyProps,
  ) {
    this.props$.next(nextProps);
  }
  public componentWillUnmount() {
    this.unmounted$.next(null);
  }

  private focusList(columnIndex: number) {
    const columns = this.columns;
    if (!columns[columnIndex]) {
      return;
    }

    // Bring focus to the sibling list.
    this.list.focus(columnIndex);
  }

  private selectFirst(columnIndex: number, reason: SelectReason) {
    const column = this.columns && this.columns[columnIndex];
    const selection = column && column.items[0];
    if (selection) {
      this.selectItem(selection.id, this.props.column, reason);
    }
  }

  private selectItem(
    selectedId: string | number | undefined,
    rootColumn: ISelectionListColumn | undefined,
    reason: SelectReason,
  ) {
    // Calculate the column hierarchy.
    const previousColumns = this.state.columns;
    const columns = rootColumn
      ? util.getColumnHierarchy(selectedId, rootColumn)
      : [];

    // Update state.
    this.setState({ columns });

    // Alert listeners.
    const from = util.deepestSelection(previousColumns);
    const to = util.deepestSelection(columns);
    this.fireSelectionChanged$.next({ reason, from, to });
  }

  private get focusColumn() {
    const { columns = [] } = this.state;
    return this.props.isFocused
      ? R.findLastIndex((item) => item.selectedIndex !== undefined, columns)
      : -1;
  }

  public render() {
    return (
      <SelectionList
        ref={this.listRef}
        {...this.props}
        columns={this.state.columns}
        focusColumn={this.focusColumn}
        onEdgeSelected={observableHandler(
          this.edge$,
          this.props.onEdgeSelected,
        )}
        onSelectionChanged={observableHandler(this.selectionChanged$)}
        onClick={observableHandler(this.click$, this.props.onClick)}
      />
    );
  }
}

/**
 * INTERNAL
 */
function observableHandler(subject: Subject<any>, bubble?: (e: any) => void) {
  return (e: any) => {
    if (bubble) {
      bubble(e); // NB: Bubble first in case any handlers adjust the event (eg. "cancel").
    }
    subject.next(e);
  };
}
