import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { GlobalState } from './types.ts';

export const useUtilSelector: TypedUseSelectorHook<GlobalState> = (selector) =>
  useSelector(selector, shallowEqual);

export const useUtilDispatch = () => useDispatch<any>();
