/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { IIcon, IIconProps } from '../../Icon/types.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { is } from 'ramda';

const ICON_COLOR_SELECTED = COLORS.WHITE;
const ICON_COLOR = color.format(-0.3);

export interface IListIconProps {
  icon?: IIcon | string;
  style?: SerializedStyles;
  onClick?: () => void;
  fill?: string | number;
  size?: number;
  offsetY?: number;
  showHighlight?: boolean;
  isSelected: boolean;
}

/**
 * Icon in a <SelectionListItem />.
 */
export const ListIcon: React.FC<IListIconProps> = React.memo(
  function ListIcon(props) {
    const {
      icon,
      style,
      onClick,
      fill = props.isSelected ? ICON_COLOR_SELECTED : ICON_COLOR,
      size = 0,
      offsetY = 0,
      showHighlight,
    } = props;

    const computedStyles = {
      base: css({
        position: 'relative',
        width: size,
        height: size,
      }),
      highlight: css({
        boxSizing: 'border-box',
        borderRadius: size,
        border: `solid 5px ${COLORS.BLUE}`,
        position: 'absolute',
        top: -9 + offsetY,
        left: -9 + offsetY,
        width: size + 18,
        height: size + 18,
        opacity: 0.2,
      }),
    };
    const elIcon =
      icon &&
      !is(String, icon) &&
      (icon as React.FC<IIconProps>)({
        fill: color.format(fill),
        size: size,
      });

    const elHighlight = showHighlight && <div css={computedStyles.highlight} />;
    return (
      <div css={[computedStyles.base, style]} onClick={onClick}>
        {elHighlight}
        {elIcon}
      </div>
    );
  },
);
