/** @jsxImportSource @emotion/react */
import React from 'react';
import { Card } from '../Card/Card.tsx';
import {
  ISelectionListItem,
  SelectionListClickEventHandler,
} from '../SelectionList/types.ts';
import { SelectionListHierarchyViewport } from '../SelectionListHierarchyViewport/SelectionListHierarchyViewport.tsx';
import { Spinner } from '../Spinner/index.ts';

export interface IDropdownListProps {
  minWidth?: number | string;
  width?: number | string;
  height?: number;
  margin?: number | string;
  isScrollable?: boolean;
  isLoading?: boolean;
  items?: ISelectionListItem[];
  emptyMessage?: string;
  onClick?: SelectionListClickEventHandler;
  onFocus?: React.FocusEventHandler<object>;
  onBlur?: React.FocusEventHandler<object>;
}

/**
 * A SelectionList within a Card for popup menus.
 */
export const DropdownList: React.FC<IDropdownListProps> = ({
  height: propsHeight,
  minWidth,
  width,
  items = [],
  isScrollable = false,
  emptyMessage,
  onFocus,
  onBlur,
  onClick,
  isLoading = false,
  margin,
}) => {
  const height = isScrollable && propsHeight === undefined ? 300 : propsHeight;

  return (
    <Card
      backgroundColor={1}
      width={width}
      minWidth={minWidth}
      height={height}
      minHeight={42}
      onFocus={onFocus}
      onBlur={onBlur}
      elevation={0}
      margin={margin}
    >
      {isLoading ? (
        <Spinner center={true} />
      ) : (
        <SelectionListHierarchyViewport
          column={{ items, isScrollable }}
          isScrollable={isScrollable}
          isStateful={false}
          onClick={onClick}
          keys$={null} // NB: No keyboard interaction allowed.
          emptyMessage={emptyMessage}
        />
      )}
    </Card>
  );
};
