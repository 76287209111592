export function getTransform(
  isFlippedX?: boolean,
  isFlippedY?: boolean,
  rotate?: number,
) {
  const scaleCss =
    isFlippedX || isFlippedY
      ? `scale(${isFlippedX ? -1 : 1}, ${isFlippedY ? -1 : 1})`
      : '';
  const rotateCss =
    rotate === undefined || rotate === 0 ? '' : `rotate(${rotate}deg)`;
  const transform = `${scaleCss} ${rotateCss}`.trim();
  return transform === '' ? undefined : transform;
}
