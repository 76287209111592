/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { containsFocus } from '@seeeverything/ui.util/src/react/react.ts';
import * as R from 'ramda';
import React from 'react';
import { Observable } from 'rxjs';
import { ListClickEvent, ListEdgeEvent } from '../List/types.ts';
import { SelectionEdge } from '../ListSelectionBehavior/types.ts';
import { EmptyMessage } from './components/EmptyMessage.tsx';
import { InnerList } from './components/InnerList/InnerList.tsx';
import {
  ISelectionListColumn,
  ISelectionListSelection,
  SelectionListChangedEvent,
  SelectionListChangedEventHandler,
  SelectionListClickEventHandler,
  SelectionListEdgeEventHandler,
  SelectionListSize,
} from './types.ts';

const DEFAULT_EMPTY_MESSAGE = 'Nothing to display.';

export interface ISelectionListOptions {
  size?: SelectionListSize;
  emptyMessage?: string | boolean;
  isStateful?: boolean;
  isScrollable?: boolean;
  isDimmedSelectable?: boolean;
  isFocused?: boolean;
  focusColumn?: number;
  tabIndex?: number;
  columnWidth?: number;
  minColumns?: number;
  hiddenColumns?: number[];
  visibleColumns?: number[];
  keys$?: Observable<React.KeyboardEvent> | null;
  onSelectionChanged?: SelectionListChangedEventHandler;
  onEdgeSelected?: SelectionListEdgeEventHandler;
  onClick?: SelectionListClickEventHandler;
}
export interface ISelectionListProps extends ISelectionListOptions {
  columns?: ISelectionListColumn[];
}

/**
 * A list (or multi-column list) of selectable display items
 * with "section headers" and "divider" options.
 */
export class SelectionList extends React.Component<
  ISelectionListProps,
  object
> {
  public static defaultProps: ISelectionListProps = {
    size: 'LARGE',
  };

  private lists: InnerList[] = [];
  private listRef = (columnIndex: number) => (ref: InnerList) =>
    (this.lists[columnIndex] = ref);

  public get columns(): ISelectionListColumn[] {
    const columns = R.reject(R.isNil, this.props.columns || []);
    return padColumns(columns, this.props.minColumns);
  }

  /**
   * Assigns focus to the list (or specified column).
   */
  public focus(columnIndex = 0) {
    const list = this.lists[columnIndex];
    if (list) {
      list.focus();
    }
  }

  /**
   * Determines whether the list (any column) has focus.
   */
  public hasFocus() {
    const { isFocused } = this.props;
    return isFocused === true || containsFocus(this);
  }

  /**
   * Determines whether the given column contains a selection.
   */
  public hasSelection(columnIndex = 0) {
    return Boolean(this.selection(columnIndex));
  }

  /**
   * Retrieves the selected item within the given column.
   */
  public selection(columnIndex = 0): ISelectionListSelection | undefined {
    const list = this.lists[columnIndex];
    const selection = list ? list.selection() : undefined;
    return selection ? { ...selection, column: columnIndex } : undefined;
  }

  /**
   * Fires the [onEdgeSelected] event callback.
   */
  public fireEdge = (columnIndex = 0, edge: SelectionEdge) => {
    const list = this.lists[columnIndex];
    return list ? list.fireEdge(edge) : undefined;
  };

  public render() {
    const { emptyMessage } = this.props;
    const columns = this.columns;
    const isEmpty =
      columns.filter((column, i) => this.isColumnVisible(i)).length === 0;

    const elEmpty = isEmpty && emptyMessage && (
      <EmptyMessage>{emptyMessage}</EmptyMessage>
    );

    const elColumns = columns.map((column, i) => this.renderColumn(column, i));

    const styles = {
      base: css({
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        boxSizing: 'border-box',
      }),
    };

    return (
      <div css={styles.base}>
        {elColumns}
        {elEmpty}
      </div>
    );
  }

  private isColumnVisible(index: number) {
    const { hiddenColumns, visibleColumns } = this.props;
    return isColumnVisible(index, visibleColumns, hiddenColumns);
  }

  private isLastColumn(index: number) {
    const columns = this.columns.map((column, i) => this.isColumnVisible(i));
    const afterColumn = columns.slice(index + 1, columns.length);
    return R.filter(Boolean, afterColumn).length === 0;
  }

  private renderColumn(column: ISelectionListColumn, index: number) {
    const isFirst = index === 0;
    const isVisible = this.isColumnVisible(index);
    const isLastColumn = this.isLastColumn(index);
    const isFocused = this.isFocused(index);
    const width = !isVisible ? 0 : column.width || this.props.columnWidth;
    const flex = !isVisible ? 0 : width === undefined ? 1 : undefined;
    const hasBorder = isVisible && !isLastColumn;
    const columnStyles = {
      base: css({
        flex,
        width,
        overflow: 'hidden',
        position: 'relative',
        boxSizing: 'border-box',
        borderRight: hasBorder ? `solid 1px ${color.format(-0.12)}` : undefined,
      }),
    };
    const isScrollable =
      column.isScrollable !== undefined
        ? column.isScrollable
        : this.props.isScrollable;

    const selectedItem =
      column.selectedIndex !== undefined
        ? column.items[column.selectedIndex]
        : undefined;
    const selectedId = selectedItem && selectedItem.id;

    const emptyMessage = this.emptyMessage(column, isFirst);

    return (
      <div key={index} css={columnStyles.base}>
        <InnerList
          ref={this.listRef(index)}
          index={index}
          items={column.items}
          selectedId={selectedId}
          size={column.size || this.props.size}
          emptyMessage={emptyMessage}
          isStateful={this.props.isStateful}
          isScrollable={isScrollable}
          isDimmedSelectable={this.props.isDimmedSelectable}
          isFocused={isFocused}
          tabIndex={this.props.tabIndex}
          keys$={this.props.keys$}
          onSelectionChanged={this.selectionChangedHandler(index)}
          onEdgeSelected={this.edgeSelectedHandler(index)}
          onClick={this.clickHandler(index)}
        />
      </div>
    );
  }

  private isFocused(columnIndex: number) {
    const { focusColumn } = this.props;
    const result =
      focusColumn !== undefined // The list knows which column has focus.
        ? columnIndex === focusColumn
        : this.props.isFocused;
    return result === undefined ? false : result; // Default false (not focused).
  }

  private emptyMessage(column: ISelectionListColumn, isFirst: boolean) {
    let message =
      column.emptyMessage !== undefined
        ? column.emptyMessage
        : this.props.emptyMessage;
    message = message === true ? DEFAULT_EMPTY_MESSAGE : (message as string);

    if (isFirst && !message) {
      message = column.emptyMessage || this.props.emptyMessage;
    }

    return message as string;
  }

  private edgeSelectedHandler = (column: number) => (e: ListEdgeEvent) => {
    const { onEdgeSelected } = this.props;
    if (onEdgeSelected) {
      onEdgeSelected({ ...e, column });
    }
  };

  private selectionChangedHandler =
    (column: number) => (e: SelectionListChangedEvent) => {
      const { onSelectionChanged } = this.props;
      if (onSelectionChanged) {
        const from = { ...e.from, column };
        const to = { ...e.to, column };
        onSelectionChanged({ ...e, from, to });
      }
    };

  private clickHandler = (column: number) => (e: ListClickEvent) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick({ ...e, column });
    }
  };
}

/**
 * INTERNAL
 */
function padColumns(
  columns: ISelectionListColumn[],
  min?: number,
): ISelectionListColumn[] {
  if (min !== undefined && columns.length < min) {
    const length = min - columns.length;
    const extras = Array.from({ length }).map(() => emptyColumn());
    columns = [...columns, ...extras];
  }
  return columns;
}

function emptyColumn(): ISelectionListColumn {
  return {
    items: [],
    emptyMessage: '', // NB: Empty string prevents default empty-message from showing.
  };
}

function isColumnVisible(
  index: number,
  visibleColumns?: number[],
  hiddenColumns?: number[],
) {
  if (visibleColumns !== undefined) {
    const notVisible = !visibleColumns.some((i) => i === index);
    if (notVisible) {
      return false;
    }
  }
  if (hiddenColumns !== undefined) {
    const isHidden = hiddenColumns.some((i) => i === index);
    if (isHidden) {
      return false;
    }
  }

  return true;
}
