/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../Text/Text.tsx';
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';

export interface ISectionHeaderProps {
  title?: string;
  isFirst?: boolean;
  isLast?: boolean;
}

/**
 * A header for a section within the list.
 */
export const SectionHeader: React.FC<ISectionHeaderProps> = ({
  title = 'Untitled',
  isFirst,
  isLast,
}) => {
  const computedStyles = {
    base: css({
      borderTopColor: isFirst ? 'transparent' : undefined,
      marginTop: isFirst ? 0 : undefined,
      marginBottom: isLast ? 0 : undefined,
    }),
  };
  return (
    <div css={css([styles.base, computedStyles.base])}>
      <Text
        size={12}
        color={color.format(-0.3)}
        uppercase={true}
        weight={FontWeight.bold}
        textShadow={[1, 1]}
        ellipsis={true}
        cursor={'default'}
      >
        {title}
      </Text>
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    boxSizing: 'border-box',
    cursor: 'default',
    backgroundColor: color.format(-0.05),
    padding: '5px 16px 4px 16px',
    borderTop: `solid 1px ${color.format(-0.03)}`,
    borderBottom: `solid 1px ${color.format(-0.03)}`,
  }),
};
