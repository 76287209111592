/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import * as R from 'ramda';
import React from 'react';
import {
  IListItemLabel,
  SelectionListSize,
} from '../../SelectionList/types.ts';
import { Text } from '../../Text/Text.tsx';

export interface ILabelProps {
  value?: string | IListItemLabel;
  isLabelSelectable?: boolean;
  isDescriptionSelectable?: boolean;
  isActive?: boolean;
  size?: SelectionListSize;
  maxWidth?: string | number;
  ellipsis?: boolean;
}

export const Label: React.FC<ILabelProps> = (props) => {
  const {
    isActive,
    size,
    ellipsis = false,
    maxWidth,
    isLabelSelectable = false,
    isDescriptionSelectable = false,
  } = props;
  const isLarge = size === 'LARGE';
  const textColor = isActive ? COLORS.WHITE : '#808080';
  const mainSize = isLarge ? 16 : 14;
  const descriptionSize = isLarge ? 11 : 10;

  const value: IListItemLabel = R.is(String, props.value)
    ? { text: props.value as string }
    : (props.value as IListItemLabel) || { text: 'Untitled' };

  const styles = {
    base: css({
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    }),
    text: css({
      whiteSpace: 'nowrap',
      maxWidth,
    }),
    description: css({
      maxWidth,
    }),
  };

  const elDescription = value.description && (
    <Text
      color={textColor}
      selectable={isDescriptionSelectable}
      cursor={isDescriptionSelectable ? 'auto' : 'pointer'}
      size={descriptionSize}
      textShadow={isActive ? [-1, -0.15] : undefined}
      ellipsis={ellipsis}
      style={styles.description}
    >
      {value.description.trim()}
    </Text>
  );

  return (
    <div css={styles.base}>
      <Text
        color={textColor}
        selectable={isLabelSelectable}
        cursor={isLabelSelectable ? 'auto' : 'pointer'}
        size={mainSize}
        textShadow={isActive ? [-1, -0.15] : undefined}
        ellipsis={ellipsis}
        style={styles.text}
      >
        {value.text.trim() || 'Untitled'}
      </Text>
      {elDescription}
    </div>
  );
};
