/** @jsxImportSource @emotion/react */
import React from 'react';
import * as R from 'ramda';
import {
  Subject,
  takeUntil,
  filter,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  map,
} from 'rxjs';
import { css } from '@emotion/react';
import {
  SelectionListHierarchy,
  ISelectionListHierarchyProps,
} from '../SelectionListHierarchy/SelectionListHierarchy.tsx';
import { LeftStack } from './components/LeftStack.tsx';
import {
  DotStatusBar,
  IStatusDot,
  DotStatusClickEvent,
} from '../DotStatusBar/index.ts';
import * as util from './util.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import {
  SelectionListChangedEvent,
  SelectionListEdgeEvent,
} from '../SelectionList/types.ts';

export interface ISelectionListHierarchyViewportProps
  extends ISelectionListHierarchyProps {
  columnTotal?: number;
}

export interface ISelectionListHierarchyViewportState {
  columnTotal: number;
  visibleColumns?: number[];
  selectedColumn: number;
  selectedViewportColumn: number;
  viewport: ISelectionListViewportRange;
}

export interface ISelectionListViewportRange {
  min: number;
  max: number;
}

/**
 * A <SelectionListHierarchy> that constrains itself
 * to a specific number of viewable columns.
 */
export class SelectionListHierarchyViewport extends React.Component<
  ISelectionListHierarchyViewportProps,
  ISelectionListHierarchyViewportState
> {
  public state: ISelectionListHierarchyViewportState = {
    columnTotal: 1,
    selectedColumn: 0,
    selectedViewportColumn: 0,
    viewport: { min: 0, max: (this.props.columnTotal || 1) - 1 },
  };

  private unmounted$ = new Subject<void>();
  private props$ = new Subject<ISelectionListHierarchyViewportProps>();
  private state$ = new Subject<Partial<ISelectionListHierarchyViewportState>>();
  private selection$ = new Subject<SelectionListChangedEvent>();
  private edge$ = new Subject<SelectionListEdgeEvent>();
  private list: SelectionListHierarchy;
  private listRef = (ref: SelectionListHierarchy) => (this.list = ref);

  public UNSAFE_componentWillMount() {
    const state$ = this.state$.pipe(takeUntil(this.unmounted$));
    const props$ = this.props$.pipe(takeUntil(this.unmounted$));
    const edge$ = this.edge$.pipe(takeUntil(this.unmounted$));
    const selection$ = this.selection$.pipe(takeUntil(this.unmounted$));

    selection$
      .pipe(
        // Keep track of the currently selected column index
        // when the selected column changes.
        filter(() => this.props.columnTotal !== undefined),
        distinctUntilChanged((prev, next) => prev.to.column === next.to.column),
      )
      .subscribe((e) => {
        this.updateSelectedColumn(e.to.column);
      });

    edge$
      .pipe(
        // Handle moving the viewport when edges are crossed.
        filter(() => this.props.columnTotal !== undefined),
        filter((e) => e.edge === 'LEFT' || e.edge === 'RIGHT'),

        map((e) => {
          const { edge } = e;
          const viewportColumn = toViewportIndex(e.column, this.state.viewport);
          const selection = e.selection.item as any;
          const hasChildren =
            selection &&
            selection.children &&
            selection.children.items.length > 0;
          return {
            hasChildren,
            edge,
            viewportColumn,
            cancel() {
              e.cancel();
            },
          };
        }),
      )
      .subscribe((e) => {
        // Shift viewport back up into parent.
        if (e.edge === 'LEFT' && e.viewportColumn === 0) {
          e.cancel(); // Prevent the embedded <SelectionListHierarchy> from also handling the edge change.
          this.previousViewport();
        }

        // Shift viewport into child column.
        if (
          e.edge === 'RIGHT' &&
          e.hasChildren &&
          e.viewportColumn === this.state.columnTotal - 1
        ) {
          e.cancel(); // Prevent the embedded <SelectionListHierarchy> from also handling the edge change.
          this.nextViewport();
        }
      });

    // Ferry state changes to React.
    state$.subscribe((state) => this.setState(state as any));

    props$
      .pipe(
        // Update the viewport when the column-total changes.
        distinctUntilKeyChanged('columnTotal'),
      )
      .subscribe((props) => {
        // Store value in state.
        const { columnTotal = 1 } = props;
        this.state$.next({ columnTotal });

        // Update calculated state values.
        this.updateViewport({ min: 0, max: columnTotal - 1 });
        this.updateSelectedColumn(0);
      });

    // Set initial values.
    this.props$.next(this.props);
    this.updateViewport(this.state.viewport);
    this.updateSelectedColumn(0);
  }

  public UNSAFE_componentWillReceiveProps(
    nextProps: ISelectionListHierarchyViewportProps,
  ) {
    this.props$.next(nextProps);
  }

  public componentWillUnmount() {
    this.unmounted$.next(null);
  }

  public focus(columnIndex?: number) {
    // When column not specified derive the deepest column that is selected.
    if (columnIndex === undefined) {
      const selection = util.deepestSelection(this.list.columns);
      columnIndex = selection ? selection.column : columnIndex;
    }
    this.list.focus(columnIndex);
  }

  public hasFocus() {
    return this.list.hasFocus(); // API pass-through.
  }

  private updateFocus(columnIndex?: number) {
    if (this.hasFocus()) {
      this.focus(columnIndex);
    }
  }

  public render() {
    const { visibleColumns } = this.state;
    const firstVisibleColumn = (visibleColumns && visibleColumns[0]) || 0;
    const hiddenColumnsLeft = R.range(0, firstVisibleColumn);
    const isStackVisible = hiddenColumnsLeft.length > 0;
    const stackWidth = LeftStack.width(hiddenColumnsLeft.length);
    const SPEED = '300ms';

    const styles = {
      base: css({
        position: 'relative',
        flex: '1 1 auto',
        display: 'flex',
        overflow: 'hidden',
        paddingLeft: isStackVisible ? stackWidth + 2 : undefined,
        paddingBottom: isStackVisible ? DotStatusBar.height : undefined,
        transition: `padding-bottom ${SPEED}`,
      }),
      leftStack: css({
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
      }),
      footerBar: css({
        position: 'absolute',
        right: 0,
        bottom: isStackVisible ? 0 : 0 - DotStatusBar.height,
        left: stackWidth,
        transition: `bottom ${SPEED}`,
        borderTop: `solid 1px ${color.format(-0.05)}`,
        boxSizing: 'border-box',
      }),
    };

    const elLeftStack = isStackVisible && (
      <LeftStack
        style={styles.leftStack}
        total={hiddenColumnsLeft.length}
        onClick={this.handleLeftStackClick}
      />
    );

    const elDotStatus = (
      <DotStatusBar
        style={styles.footerBar}
        items={this.statusDots}
        background={-0.15}
        dotColor={1}
        onPreviousClick={this.handleLeftStackClick}
        onDotClick={this.handleDotClick}
        childTabIndex={isStackVisible ? 0 : -1}
      />
    );

    return (
      <div css={styles.base}>
        <SelectionListHierarchy
          ref={this.listRef}
          {...this.props}
          column={this.props.column}
          minColumns={this.props.columnTotal}
          visibleColumns={this.state.visibleColumns}
          onSelectionChanged={observableHandler(
            this.selection$,
            this.props.onSelectionChanged,
          )}
          onEdgeSelected={observableHandler(
            this.edge$,
            this.props.onEdgeSelected,
          )}
        />
        {elDotStatus}
        {elLeftStack}
      </div>
    );
  }

  private get statusDots(): IStatusDot[] {
    const { visibleColumns = [] } = this.state;
    const columns = (this.list && this.list.columns) || [];
    const lastVisible = visibleColumns[visibleColumns.length - 1];
    const subset = columns.slice(0, lastVisible + 1);
    return subset.map((_, i) => {
      const isVisible = visibleColumns.some((item) => item === i);
      const type: IStatusDot['type'] = isVisible ? 'FILLED' : 'OPEN';
      const dot: IStatusDot = { type };
      return dot;
    });
  }

  private updateSelectedColumn(selectedColumn: number) {
    const selectedViewportColumn = toViewportIndex(
      selectedColumn,
      this.state.viewport,
    );
    this.state$.next({ selectedColumn, selectedViewportColumn });
  }

  private updateViewport(viewport: ISelectionListViewportRange) {
    const visibleColumns = R.range(viewport.min, viewport.max + 1);
    this.state$.next({ viewport, visibleColumns });
  }

  private shiftViewport(
    increment: number,
    viewport?: ISelectionListViewportRange,
  ) {
    viewport = viewport || this.state.viewport;
    const columnTotal = this.state.columnTotal;
    const result = { ...viewport };

    // Increment the value (+/-).
    result.min += increment;
    result.max += increment;

    // Ensure the viewport is within range.
    result.min = result.min < 0 ? 0 : result.min;
    result.max = result.max < columnTotal - 1 ? columnTotal - 1 : result.max;
    return result;
  }

  private nextViewport() {
    const viewport = this.shiftViewport(+1, this.state.viewport);
    this.updateViewport(viewport);

    // If a single column then ensure the first item is selected.
    if (this.state.columnTotal === 1) {
      this.selectItem(viewport.min, 0);
    }
  }
  private previousViewport() {
    const viewport = this.shiftViewport(-1, this.state.viewport);
    this.updateViewport(viewport);

    // If a single column then ensure the current selection is updated.
    if (this.state.columnTotal === 1) {
      const columnIndex = viewport.min;
      const column = this.list.columns[columnIndex];
      this.selectItem(columnIndex, column.selectedIndex || 0);
    }
  }

  private handleLeftStackClick = () => {
    this.previousViewport();
    this.focus();
  };

  private handleDotClick = (e: DotStatusClickEvent) => {
    if (e.index === this.state.viewport.min || e.type === 'FILLED') {
      return;
    }
    this.fireColumnSelectionChanged(e.index);
    this.updateViewport({
      min: e.index,
      max: e.index + (this.state.columnTotal - 1),
    });
    this.focus(e.index);
  };

  private selectItem(columnIndex: number, itemIndex: number) {
    this.fireColumnSelectionChanged(columnIndex, itemIndex);
    this.updateFocus(columnIndex);
  }

  private fireColumnSelectionChanged(columnIndex: number, itemIndex?: number) {
    const { onSelectionChanged } = this.props;
    if (onSelectionChanged) {
      const from = util.toSelection(
        this.state.selectedColumn,
        this.list.columns[this.state.selectedColumn],
      );
      const to = util.toSelection(
        columnIndex,
        this.list.columns[columnIndex],
        itemIndex,
      );
      onSelectionChanged({ from, to, reason: 'SELECTED' });
    }
  }
}

/**
 * INTERNAL
 */
function observableHandler(subject: Subject<any>, bubble?: (e: any) => void) {
  return (e: any) => {
    if (bubble) {
      bubble(e); // NB: Bubble first in case any handlers adjust the event (eg. "cancel").
    }
    subject.next(e);
  };
}

function toViewportIndex(
  selectedColumn: number,
  viewport: ISelectionListViewportRange,
) {
  const min = viewport.min;
  const result = min === -1 ? selectedColumn : selectedColumn - min;
  return result;
}
