/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles.js';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

/**
 * The general interface for the wrapped button.
 */
export interface IInnerButtonProps {
  isEnabled?: boolean;
  isOver?: boolean;
  isPressed?: boolean;
  isFocused?: boolean;
  cursor?: CSSProperties['cursor'];
  size?: number | string;
  fill?: string;
  style?: SerializedStyles;
}

export interface IButtonState {
  isPressed: boolean;
  isOver: boolean;
  isFocused: boolean;
}

export type ButtonType = React.ReactElement<IInnerButtonProps>;
export type ButtonClickEvent =
  | React.MouseEvent<HTMLElement>
  | React.KeyboardEvent<HTMLElement>;
export type ButtonClickEventHandler = (e: ButtonClickEvent) => void;
export interface IButtonProps {
  tooltip?: string;
  fill?: string;
  dataTest?: string;
  children: ButtonType;
  centerRipple?: boolean;
  disableRipple?: boolean;
  isEnabled?: boolean;
  isPressed?: boolean;
  isStateful?: boolean;
  hoverAlpha?: number;
  margin?: string | number;
  width?: string | number;
  height?: string | number;
  display?: CSSProperties['display'];
  visibility?: CSSProperties['visibility'];
  tabIndex?: number;
  style?: CSSProperties;
  onClick?: ButtonClickEventHandler;
  onDoubleClick?: () => void;
  onMouseDown?: ButtonClickEventHandler;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const useButtonStyles = (props: IButtonProps) => {
  const {
    style,
    display = 'inline-block',
    visibility,
    margin,
    width,
    hoverAlpha = 0.04,
    fill = '#000',
    isEnabled = true,
    onClick,
    height,
  } = props;
  return makeStyles({
    root: {
      display,
      visibility,
      margin,
      width,
      height,
      opacity: !isEnabled && onClick ? 0.5 : 1,
      cursor: 'pointer',
      outline: 'none',
      borderRadius: 3,
      color: color.create(fill).alpha(0.4).css(), // Changes the ripple color.
      '&:hover': {
        background: color.create(fill).alpha(hoverAlpha).css(),
      },
      ...CommonStyles.MaterialCubicTransitions,
      transitionDuration: '150ms',
      ...style,
    },
  });
};

/**
 * A HoC that provides standard clickable button behavior.
 */
export const Button: React.FC<IButtonProps> = (props) => {
  const {
    dataTest,
    children,
    tabIndex = 0,
    onDoubleClick,
    isEnabled = true,
    onMouseEnter,
    onMouseLeave,
    isStateful,
    onClick,
    onMouseDown,
    centerRipple,
    disableRipple,
    tooltip,
  } = props;

  const [isOver, setIsOver] = React.useState(false);
  const [isPressed, setIsPressed] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleOver = (toIsOver: boolean) => {
    setIsOver(isEnabled ? toIsOver : false);
    // Un-press the button if the user click down on it, then moved the mouse away
    // from the button before releasing.
    if (!toIsOver && isPressed && !isStateful) {
      setIsPressed(false);
    }
    // Alert listeners.
    if (toIsOver && onMouseEnter) {
      onMouseEnter();
    }
    if (!toIsOver && onMouseLeave) {
      onMouseLeave();
    }
  };
  const handleMouseEnter = () => handleOver(true);
  const handleMouseLeave = () => handleOver(false);
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    ) => {
      if (
        (e as React.MouseEvent<HTMLDivElement>).button &&
        (e as React.MouseEvent<HTMLDivElement>).button !== 0
      ) {
        return; // Only respond to the left-mouse button click.
      }
      if (!isEnabled) {
        return;
      }

      setIsOver(false);

      // Invoke click handlers.
      if (onClick) {
        onClick(e);
      }
      if ((!isStateful || !isPressed) && onMouseDown) {
        onMouseDown(e);
      }
    },
    [isEnabled, isPressed, isStateful, onClick, onMouseDown],
  );

  React.useEffect(() => {
    if (props.isPressed !== undefined) {
      setIsPressed(props.isPressed);
    }
  }, [props.isPressed]);

  const classes = useButtonStyles(props)();

  // NOTE: Only the button properties will be injected into the child component if its a React Component. If its a DOM node i.e. <div>
  // they will not be injected as React throws a warning about unsupported props on a DOM element.
  const el = React.useMemo(
    () =>
      isDOMTypeElement(children)
        ? children
        : React.cloneElement(children, {
            isEnabled,
            isOver,
            isPressed,
            isFocused,
          }),
    [children, isEnabled, isOver, isPressed, isFocused],
  );

  const elContent = (
    <ButtonBase
      data-test={dataTest}
      tabIndex={tabIndex}
      onClick={handleClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      component={'div'}
      centerRipple={centerRipple}
      disableRipple={disableRipple}
      classes={{ root: classes.root }}
    >
      {el}
    </ButtonBase>
  );

  return tooltip ? <Tooltip title={tooltip}>{elContent}</Tooltip> : elContent;
};

/**
 * Checks if a supplied element is a DOM node.
 * @see https://stackoverflow.com/questions/33199959/how-to-detect-a-react-component-vs-a-react-element
 */
function isDOMTypeElement(element: any) {
  return React.isValidElement(element) && typeof element.type !== 'function';
}
