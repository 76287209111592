/**
 * Global events subscribed to only once and consumed as [Observable] event producers.
 */
import { IS_BROWSER } from '../constants/constants.ts';
import {
  animationFrameScheduler,
  fromEvent,
  Subject,
  observeOn,
  share,
} from 'rxjs';

const fromDocumentEvent = <T>(event: string) =>
  IS_BROWSER ? fromEvent<T>(document, event) : new Subject<T>();

const fromWindowEvent = <T>(event: string) =>
  IS_BROWSER ? fromEvent<T>(window, event) : new Subject<T>();

export const keydown$ = fromDocumentEvent<React.KeyboardEvent>('keydown');
export const keyup$ = fromDocumentEvent<React.KeyboardEvent>('keyup');
export const resize$ = fromWindowEvent<unknown>('resize').pipe(
  observeOn(animationFrameScheduler),
  share(),
);
