/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { animation } from '@seeeverything/ui.util/src/animation/index.ts';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import {
  useUtilDispatch,
  useUtilSelector,
} from '@seeeverything/ui.util/src/redux/store.ts';
import { useCallback, useEffect, useRef } from 'react';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';

export interface IScrollProps {
  children: React.ReactNode;
  containerId?: string;
  onScroll?: (scrollTop: number) => void;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  rootId?: string;
  containerZIndex?: number;
  childrenZIndex?: number;
}

/**
 * Wraps a React component providing advanced
 * scroll behavior and styling.
 *
 * NOTE:
 *    Elements that use Scroll assume absolute positioning to
 *    fill their entire parent. Make sure the parent is set with
 *    `position: relative` so that it relative sizing is assumed
 *    by the Scroll-ing component.
 */
export const ScrollContainer: React.FC<IScrollProps> = ({
  children,
  childrenZIndex,
  containerId,
  containerZIndex,
  onScroll,
  paddingLeft = 0,
  paddingRight = 0,
  paddingTop = 0,
  rootId,
}) => {
  const timeoutHandler = useRef<NodeJS.Timeout>(undefined);
  const dispatch = useUtilDispatch();
  const containerState = useUtilSelector((state) => {
    if (!containerId) return;
    return state.scroll.containers[containerId];
  });

  const rootDivRef = useRef<HTMLDivElement>(null);

  const scrollToId = useCallback((id: string) => {
    const element = document.getElementById(id);
    if (!element) return;

    element.scrollIntoView();
  }, []);

  const scrollSmooth = useCallback((cssSelector: string, offsetPx: number) => {
    const contentContainer = getContentContainer(rootDivRef.current);
    if (!contentContainer) return;

    const targetElement =
      contentContainer.querySelector<HTMLElement>(cssSelector);
    if (!targetElement) return;

    const targetIndex = getContentChildren(contentContainer).findIndex(
      (child) => child === targetElement,
    );
    const targetScrollTop = getEdgeAdjustedItemTop(
      contentContainer,
      targetIndex,
    );

    if (targetScrollTop === undefined) return;

    return new Promise<void>((resolve, reject) => {
      const parentContainer = getParentContainer(rootDivRef.current);
      if (!parentContainer) return resolve();

      // Slide animation.
      animation
        .start$({
          current: () => ({ scrollTop: parentContainer.scrollTop }),
          target: {
            scrollTop: targetIndex === 0 ? 0 : targetScrollTop + offsetPx,
          },
          duration: 200,
          type: 'easeInOut',
        })
        .subscribe({
          next: (to) => {
            if (!rootDivRef.current) return;
            getParentContainer(rootDivRef.current).scrollTop = to.scrollTop;
          },
          error: reject,
          complete: resolve,
        });
    });
  }, []);

  useEffect(() => {
    if (!containerId) return;
    if (!containerState) return;

    const { scrollToDataId, offsetPx, smooth } = containerState;
    if (!scrollToDataId) return;

    if (smooth) {
      scrollSmooth(`[data-id="${scrollToDataId}"]`, offsetPx);
    } else {
      scrollToId(scrollToDataId);
    }

    // Timeout is to allow any css animations to complete.
    clearTimeout(timeoutHandler.current);
    timeoutHandler.current = setTimeout(() => {
      dispatch(
        scrollSlice.clearScrollToDataId({ scrollContainerId: containerId }),
      );
    }, 400);
  }, [containerId, containerState, dispatch, scrollSmooth, scrollToId]);

  useEffect(() => {
    const timeout = timeoutHandler.current;
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleScroll = useCallback(
    (e: positionValues) => onScroll?.(e.scrollTop),
    [onScroll],
  );

  const elTrackVertical = useCallback(
    () => <div css={styles.scrollbarTrackVertical(containerZIndex)} />,
    [containerZIndex],
  );

  const elScrollbarView = useCallback(
    () => <div css={styles.scrollbarView} />,
    [],
  );

  const computedStyles = {
    children: css({
      position: 'absolute',
      inset: `${paddingTop}px ${paddingRight}px 0 ${paddingLeft}px`,
      boxSizing: 'border-box',
      zIndex: childrenZIndex,
    }),
  };

  return (
    <Scrollbars
      className={'scroll-behavior'}
      onScrollFrame={handleScroll}
      renderTrackVertical={elTrackVertical}
      renderView={elScrollbarView}
    >
      <div ref={rootDivRef}>
        <div id={rootId} css={computedStyles.children}>
          {children}
        </div>
      </div>
    </Scrollbars>
  );
};

const getEdgeAdjustedItemTop = (
  contentContainer: HTMLElement,
  targetIndex: number,
) =>
  contentContainer &&
  getContentChildren(contentContainer)[targetIndex]?.offsetTop;

const getParentContainer = (root?: Element) =>
  root?.parentElement as HTMLElement;
const getContentContainer = (root?: Element) => root?.firstChild as HTMLElement;

const getContentChildren = (container: HTMLElement) =>
  Array.from(container.childNodes) as HTMLElement[];

const styles = {
  scrollbarTrackVertical: (zIndex?: number) =>
    css({
      position: 'absolute',
      right: 2,
      bottom: 2,
      top: 2,
      borderRadius: 3,
      zIndex,
      width: 15,
      overflow: 'hidden',
    }),
  scrollbarView: css({
    WebkitOverflowScrolling: 'touch',
    position: 'absolute',
    inset: 0,
    marginBottom: 0,
    marginRight: -15,
    overflow: 'scroll',
    overflowY: 'scroll',
    overflowX: 'hidden',
  }),
};
