/** @jsxImportSource @emotion/react */
import { markdown } from '@seeeverything/ui.util/src/markdown/markdown.ts';
import React from 'react';

/**
 * Markdown into DOM element.
 */
export function formatMarkdown(children: React.ReactNode) {
  if (typeof children !== 'string') {
    return children;
  }

  const html = markdown(children);
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
