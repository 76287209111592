export type Visibility = 'visible' | 'hidden';

export enum FontWeight {
  thin = 100,
  light = 300,
  normal = 400,
  medium = 500,
  bold = 700,
  black = 900,
}

/**
 * Allows all nested properties to be Partial.
 */
export type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };

export type AuthenticatedUser = {
  id: string;
  firstName: string;
  lastName: string;
  positionTitle: string;
  primaryTeam?: PrimaryTeam;
  emailDomain: string;
  permissions: UserPermissions;
};

export type UserPermissions = {
  canAccessReporting: boolean;
};

export type PrimaryTeam = {
  id: string;
  name: string;
  path: string;
  role?: string;
};

export type Paged<T> = {
  items: T[];
  hasNextPage: boolean;
};

export type PageInfoResponse = {
  currentPage: number;
  hasNextPage: boolean;
  pageSize: number;
  totalCount: number;
  totalPages: number;
};

export type PageInfoRequest = {
  pageSize: number;
  pageNumber: number;
};

export type OrderBy = {
  fieldName: string;
  direction: 'Ascending' | 'Descending';
};

export type ModuleType = 'coaching' | 'compliance' | 'cadence';
