/** @jsxImportSource @emotion/react */
import { IStatusDot, DotStatusClickEventHandler } from '../types.ts';
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IDotProps extends IStatusDot {
  index: number;
  color?: number | string;
  onClick?: DotStatusClickEventHandler;
}

const SIZE = 10;

/**
 * A single dot within a <DotStatus>
 */
export const Dot = (props: IDotProps) => {
  const { type, onClick } = props;
  const dotColor = color.format(props.color || 1);
  const styles = {
    base: css({
      color: dotColor,
      cursor: onClick && type !== 'FILLED' ? 'pointer' : 'default',
      backgroundColor: type === 'FILLED' ? dotColor : undefined,
      boxSizing: 'border-box',
      width: SIZE,
      height: SIZE,
      border: `solid 2px ${dotColor}`,
      borderRadius: SIZE,
      marginRight: 8,
      ':last-child': { marginRight: 0 },
    }),
  };
  return <div css={styles.base} onClick={clickHandler(props)} />;
};

function clickHandler(props: IDotProps) {
  return () => {
    const { onClick, index, type } = props;
    if (onClick && type !== 'FILLED') {
      onClick({ index, type });
    }
  };
}
