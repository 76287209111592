import { css, keyframes } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';

export const CommonStyles = {
  MaterialCubicTransitions: {
    transition: '400ms all',
    transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
  },
  BoxShadowGroup: {
    boxShadow: `0 1px 4px 0 #a0a0a0`,
    transition: 'all 0.3s',
    borderRadius: 4,
    ':hover': {
      boxShadow: `0 1px 6px 0 #808080`,
    },
  },
  AnimationShake: css({
    animation: `${keyframes({
      '0%': { transform: 'translateX(0)' },
      '25%': { transform: 'translateX(5px)' },
      '50%': { transform: 'translateX(-5px)' },
      '75%': { transform: 'translateX(5px)' },
      '100%': { transform: 'translateX(0)' },
    })} 0.2s ease-in-out`,
  }),
  ScrollPulse: css({
    animation: `${keyframes({
      '0%': { backgroundColor: color.create(COLORS.BLUE).alpha(0.25).hex() },
      '100%': { backgroundColor: color.create(COLORS.BLUE).alpha(0.1).hex() },
    })} 0.33s ease-in-out`,
  }),
};
