import { is } from 'ramda';

/**
 * Determines whether the given value is a number, or can be
 * parsed into a number.
 *
 * NOTE: Examines string values to see if they are numeric.
 *
 * @param value: The value to examine.
 * @returns true if the value is a number.
 */
export const isNumber = (value: string | number): value is number => {
  if (value === undefined) return false;
  if (value === null) return false;

  return !isNaN(Number(value));
};

/**
 * Takes an input or an array of inputs and returns as an array.
 * @param input Input value or array of input values.
 */
export const asArray = <T>(input: T | T[]): T[] =>
  Array.isArray(input) ? input : [input];

/**
 * Rounds to the given precision.
 */
export function round(value: number, precision = 0) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

/**
 * Determines whether the given value is a Promise.
 */
export const isPromise = (value?: any) =>
  is(Object, value) && is(Function, value.then);

/**
 * Returns a string representation of a percentage, e.g. 0.7 returns "70%".
 */
export function toPercentageString(fraction: number, precision = 0) {
  return fraction === 0 ? '0%' : (fraction * 100).toFixed(precision) + '%';
}
