/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import * as R from 'ramda';
import React from 'react';
import {
  IListItemLabel,
  ISelectionListItem,
  SelectionListSize,
} from '../../SelectionList/types.ts';
import { Label } from './Label.tsx';

export interface IContentProps {
  data?: ISelectionListItem;
  content?: React.ReactNode | IListItemLabel;
  isEnabled?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  isFocused?: boolean;
  isLabelSelectable?: boolean;
  isDescriptionSelectable?: boolean;
  hasChildren?: boolean;
  size?: SelectionListSize;
  style?: SerializedStyles;
  maxWidth?: string | number;
}

export const Content: React.FC<IContentProps> = React.memo(
  function Content(props) {
    const {
      isActive,
      size,
      content,
      isLabelSelectable,
      isDescriptionSelectable,
    } = props;
    const maxWidth =
      props.maxWidth ?? (content as IListItemLabel).maxWidth ?? undefined;

    const isComponent = React.isValidElement(content);

    const isLabel =
      !isComponent &&
      (R.is(String, content) ||
        R.is(Number, content) ||
        R.is(String, content && (content as IListItemLabel).text));

    const elLabel = isLabel && (
      <Label
        value={content as string | IListItemLabel}
        isActive={isActive}
        size={size}
        isLabelSelectable={isLabelSelectable}
        isDescriptionSelectable={isDescriptionSelectable}
        maxWidth={maxWidth}
        ellipsis={maxWidth ? true : undefined}
      />
    );

    const elComponent = isComponent && (content as React.ReactElement<any>);

    return (
      <div css={[styles.base, props.style]}>
        {elLabel}
        {elComponent}
      </div>
    );
  },
);

const styles = {
  base: css({
    flex: '1 1 auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  }),
};
